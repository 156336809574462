import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * Trova il parametro 'companyName' in un albero di rotte
 *
 * @param route
 * @returns Il valore del parametro 'companyName' o null
 */
export function findCompanyName(route: ActivatedRouteSnapshot) {
  // Ottieni l'intero albero delle rotte
  let currentRoute: ActivatedRouteSnapshot = route.root;
  let companyName: string | null = null;

  // Cicla finché non trovi un parametro 'companyName'
  while (currentRoute.children.length > 0 && !companyName) {
    currentRoute = currentRoute.firstChild!;
    companyName = currentRoute.paramMap.get('companyName');
  }

  return companyName;
}
