import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ageValidator(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formValue = control.value;
    const { year, month, day } = formValue;

    if (!year || !month || !day) {
      return null;
    }

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (
      age > minAge ||
      (age === minAge && monthDiff > 0) ||
      (age === minAge && monthDiff === 0 && dayDiff >= 0)
    ) {
      return null;
    } else {
      return { ageInvalid: true };
    }
  };
}
