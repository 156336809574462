import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { User } from '@labgo/commons';
import { PWAUserService } from '../services/pwa-user.service';

@Injectable({
  providedIn: 'root',
})
export class PWAUserResolver implements Resolve<User> {
  constructor(private pwaUserService: PWAUserService, private router: Router) {}

  resolve(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> {
    return this.pwaUserService
      .fetchMyUser()
      .pipe(switchMap(() => this.pwaUserService.whenCurrentUser()));
  }
}
