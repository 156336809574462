import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  Advertisement,
  Browser,
  InstantWinAttemptResponse,
  LabgoEnvironment,
  LabgoEvent,
  Product,
  QrState,
} from '@labgo/commons';

import { catchError, shareReplay, tap } from 'rxjs/operators';
import { PWACompanyService } from './pwa-company.service';

export interface QrInfoResponse {
  advertisement?: Advertisement;
  advertisements?: Advertisement[];
  product: Product;
  state: QrState;
  hideStatusMessage: boolean;
  redirectUrl?: string;
}

export interface QrCheckResponse {
  checkId: string;
  succeeded: boolean;
  state?: QrState;
  message: string;
  alreadyReported: boolean;
  advertisement?: Advertisement;
  falsification?: boolean;
  colorCheck?: boolean;
  companyName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QRService {
  constructor(
    @Inject('env') private env: LabgoEnvironment,
    private http: HttpClient,
    private pwaCompanyService: PWACompanyService
  ) {}

  checkQrCode(
    code: string,
    qrImage?: string,
    browser?: string,
    metadata?: string,
    deviceInfo?: string
  ): Observable<QrCheckResponse> {
    const data = new FormData();
    if (qrImage) data.append('file', qrImage);
    if (browser) data.append('browser', browser);
    if (metadata) data.append('metadata', JSON.stringify(metadata));
    if (deviceInfo) data.append('deviceInfo', JSON.stringify(deviceInfo));

    return this.http.post<QrCheckResponse>(
      `${this.env.server.url}/Qrcode/check/${code}`,
      data
    );
  }

  getQRCodeInfo(code: string, checkId?: string): Observable<QrInfoResponse> {
    let httpParams = new HttpParams();
    if (checkId) httpParams = httpParams.append('checkId', checkId);

    return this.http.get<QrInfoResponse>(
      `${this.env.server.url}/Qrcode/${code}/info`,
      { params: httpParams }
    );
  }

  precheckQrCode(
    code: string,
    browser: string,
    deviceInfo: any
  ): Observable<QrCheckResponse> {
    return this.http.post<QrCheckResponse>(
      `${this.env.server.url}/Qrcode/precheck/${code}`,
      {
        browser,
        deviceInfo: JSON.stringify(deviceInfo),
      }
    );
  }

  // Associate QR to authenticated user
  private inProgressLinkRequest: Observable<QrCheckResponse> | null = null;
  linkQrCode(code: string, checkId: string): Observable<QrCheckResponse> {
    if (!this.inProgressLinkRequest) {
      this.inProgressLinkRequest = this.http
        .post<QrCheckResponse>(
          `${this.env.server.url}/Qrcode/${code}/link?checkId=${checkId}`,
          {}
        )
        .pipe(
          shareReplay(1),
          catchError((error) => {
            // Clear the cache if there's an error to allow retrying the request
            this.inProgressLinkRequest = null;
            throw error;
          }),
          tap(() => {
            // Clear the cache once the request completes successfully
            this.inProgressLinkRequest = null;
          })
        );
    }

    return this.inProgressLinkRequest;
  }

  getProductInfo(qrId: string): Observable<Product> {
    return this.http.get<Product>(
      `${this.env.server.url}/Qrcode/${qrId}/product`
    );
  }

  getEventInfo(qrId: string): Observable<LabgoEvent> {
    return this.http.get<LabgoEvent>(
      `${this.env.server.url}/Qrcode/${qrId}/product`
    );
  }

  attemptInstantWin(qrId: string, checkId: string) {
    return this.http.post<InstantWinAttemptResponse>(
      `${this.env.server.url}/Qrcode/instantWin/${qrId}?checkId=${checkId}`,
      {}
    );
  }

  parseBrowserName(browser: Browser) {
    return browser.name + '-v' + browser.version;
  }

  getBrowser(): Browser {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  getDeviceInfo() {
    let deviceInfo: {};

    if (navigator && navigator?.userAgent) {
      const userAgent = navigator?.userAgent;
      deviceInfo = { userAgent };
    } else deviceInfo = {};

    return deviceInfo;
  }
}
