import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { PWACompanyService } from '../services/pwa-company.service';
import { findCompanyName } from '../utils/findCompanyName';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    router: Router,
    protected keycloak: KeycloakService,
    private PWACompanyService: PWACompanyService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      const companyName = findCompanyName(route);
      console.log('AuthGuard -> isAccessAllowed -> companyName', companyName);
      if (!companyName) return this.router.navigate(['/']);

      this.PWACompanyService.fetchCompanyByName(companyName);

      let redirectUri = this.constructRedirectUri(state.url, companyName);
      await this.keycloak.login({ redirectUri });
      return false;
    }
    return true; // Allow navigation
  }

  private constructRedirectUri(
    currentUrl: string,
    companyName: string
  ): string {
    const baseUrl = window.location.origin;

    let redirectUri = baseUrl + currentUrl;

    // Append company name as a query parameter
    redirectUri += currentUrl.includes('?') ? '&' : '?';
    redirectUri += `companyName=${companyName}`;

    return redirectUri;
  }
}
