import { Injectable } from '@angular/core';
import { ErrorResponse } from '@labgo/commons';
import { BehaviorSubject } from 'rxjs';
import { QrCheckResponse } from './qr.service';

@Injectable({
  providedIn: 'root',
})
export class QrResponseService {
  qrResp: BehaviorSubject<Partial<QrCheckResponse>> = new BehaviorSubject<
    Partial<QrCheckResponse>
  >({});

  errorResp: BehaviorSubject<ErrorResponse | null> =
    new BehaviorSubject<ErrorResponse | null>(null);

  constructor() {}

  setResponse(qrResp: QrCheckResponse) {
    this.qrResp.next(qrResp);
  }

  getResponse() {
    return this.qrResp.asObservable();
  }

  setError(error: ErrorResponse) {
    this.errorResp.next(error);
  }

  getError() {
    return this.errorResp.asObservable();
  }
}
