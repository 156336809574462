import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  CHECKID_TO_LINK_STORAGE_KEY,
  LocalStorageService,
  QRID_TO_LINK_STORAGE_KEY,
  QrState,
} from '@labgo/commons';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GeolocationService } from '../../core/services/geolocation.service';
import { QRService } from '../../core/services/qr.service';
import { PWAAuthService } from '../services/pwa-auth.service';
import { QrResponseService } from '../services/qr-response.service';

@Injectable({
  providedIn: 'root',
})
export class QRConfigGuard implements CanActivate {
  constructor(
    private router: Router,
    private qrService: QRService,
    private pwaAuthService: PWAAuthService,
    private geolocationService: GeolocationService,
    private qrResponseService: QrResponseService,
    private localStorageService: LocalStorageService
  ) {}

  parseBrowserName(browser: { name: string; version: string }) {
    return browser.name + '-v' + browser.version;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const q = route.queryParams.q;

    const browser = this.qrService.parseBrowserName(
      this.qrService.getBrowser()
    );
    const deviceInfo = this.qrService.getDeviceInfo();

    if (!q) {
      // this.gaService.event('scan_check', 'qr_guard', 'Verifica scansione');
      console.log('QRConfigGuard - no {q} param');
      return true;
    }

    return this.qrService.precheckQrCode(q, browser, deviceInfo).pipe(
      switchMap((qrResp) => {
        if ((qrResp as any).redirectUrl) {
          window.location.href = (qrResp as any).redirectUrl;
          return of(false);
        }

        this.geolocationService.getGeolocation();
        this.geolocationService.nextQrId(qrResp.checkId);

        this.qrResponseService.setResponse(qrResp);

        // se non sono autenticato, mi salvo il qrId per poi Linkarlo all'utente loggato
        this.pwaAuthService.isAutenticated().then((isAuthenticated) => {
          if (!isAuthenticated) {
            this.localStorageService.set(QRID_TO_LINK_STORAGE_KEY, q);
          }
        });

        this.localStorageService.set(
          CHECKID_TO_LINK_STORAGE_KEY,
          qrResp.checkId
        );

        const companyName = route.parent?.params.companyName;

        if (qrResp.state === QrState.Inactive) {
          this.router.navigate(['/', companyName, 'pages', 'inactive', q]);
          return of(false);
        }

        // Event
        if (!qrResp.falsification) {
          this.router.navigate([
            '/',
            companyName,
            'pages',
            'event',
            q,
            qrResp.checkId,
          ]);
          return of(false);
        }

        // Product
        this.router.navigate([
          '/',
          companyName,
          'pages',
          'authentication',
          q,
          qrResp.checkId,
        ]);
        return of(false);
      })
    );
  }
}
