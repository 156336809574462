import { HttpInterceptorFn } from '@angular/common/http';

const _TOKEN = 'wYkZEAa9iFNsm-HKjY@X2Bt.R!e6J*8c';

export const basicAuthInterceptor: HttpInterceptorFn = (req, next) => {
  if (
    req.url.includes('Company/name') ||
    req.url.includes('Qrcode/') ||
    req.url.includes('User/') ||
    req.url.includes('Report/')
  ) {
    req = req.clone({
      setHeaders: {
        Authorization: `Basic ${_TOKEN}`,
      },
    });
  }

  return next(req);
};
