import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  COMPANY_NAME_STORAGE_KEY,
  Company,
  LabgoEnvironment,
  LabgoLoggerService,
  LocalStorageService,
  SkinService,
  TermsAndConditionsCompany,
  dasherize,
} from '@labgo/commons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PWACompanyService {
  currentCompany!: Company;
  currentCompany$ = new ReplaySubject<Company>(1);

  constructor(
    @Inject('env') private env: LabgoEnvironment,
    private http: HttpClient,
    private skinService: SkinService,
    private router: Router,
    private logger: LabgoLoggerService,
    private localStorageService: LocalStorageService
  ) {}

  whenCurrentCompany() {
    return this.currentCompany$.asObservable();
  }

  setCurrentCompany(company: Company) {
    this.logger.debug(
      '%c Set Current Company',
      'color:yellow;background:#000',
      company.businessName,
      company
    );
    this.currentCompany$.next(company);
    this.currentCompany = company;
    this.skinService.setSkin(company);
  }

  getCompany(id: string): Observable<Company> {
    return this.http.get<Company>(`${this.env.server.url}/Company/${id}`);
  }

  fetchCompanyByName(companyName: string) {
    this.http
      .get<Company>(`${this.env.server.url}/Company/name/${companyName}`)
      .subscribe({
        next: (company) => {
          this.localStorageService.set(COMPANY_NAME_STORAGE_KEY, companyName);
          this.setCurrentCompany(company);
        },
        error: (err) => {
          this.logger.error(
            'ERROR fetchCompanyByName - Company not found',
            err
          );
          this.router.navigate(['/']);
        },
      });
  }

  getNameDasherized() {
    return dasherize(this.currentCompany.name);
  }

  getTermsAndConditions(
    id: string
  ): Observable<Partial<TermsAndConditionsCompany>> {
    return this.http.get<Partial<TermsAndConditionsCompany>>(
      `${this.env.server.url}/Company/${id}/terms`
    );
  }
}
