import { Inject, Injectable } from '@angular/core';
import { LabgoEnvironment } from '../models/labgo-environment.interface';

let labgoLoggerServiceInstance: LabgoLoggerService;
export const logger = () => labgoLoggerServiceInstance;

@Injectable({
  providedIn: 'root',
})
export class LabgoLoggerService {
  constructor(@Inject('env') private env: LabgoEnvironment) {
    labgoLoggerServiceInstance = this;
  }

  debug(message: string, ...data: any) {
    if (this.env.debug)
      console.log(`%c ${message} `, `background: #bb4500; color:#fff`, ...data);
  }

  log(message: string, ...data: any) {
    console.log(`%c ${message} `, `background: #0066cc; color:#fff`, ...data);
  }

  error(message: string, ...data: any) {
    console.error(`%c ${message} `, `background: red; color:#fff`, ...data);
  }
}
