export interface TouchpointType {
  id: string;
  name: string;
}

export enum TouchpointTypeEnum {
  Touchpoint15mm = 'Touchpoint15mm',
  Touchpoint20mm = 'Touchpoint20mm',
  TouchpointTNT = 'TouchpointTNT',
  Hangtag = 'Hangtag',
  HangtagHeritageGreen = 'HangtagHeritageGreen',
  HangtagCoreRed = 'HangtagCoreRed',
  HeatTransferableTNT = 'HeatTransferableTNT',
}

export const TouchpointTypeEnumMap = {
  [TouchpointTypeEnum.Touchpoint15mm]: $localize`Touchpoint Piccolo 16x17mm`,
  [TouchpointTypeEnum.Touchpoint20mm]: $localize`Touchpoint Grande 24x27mm`,
  [TouchpointTypeEnum.TouchpointTNT]: $localize`Touchpoint TNT (Etichetta tessuto non tessuto) `, // Non-Woven Fabric
  [TouchpointTypeEnum.Hangtag]: $localize`Hangtag con Touchpoint applicato`,
  [TouchpointTypeEnum.HangtagHeritageGreen]: $localize`Hangtag Heritage Verde + Touchpoint applicato`,
  [TouchpointTypeEnum.HangtagCoreRed]: $localize`Hangtag Core Rosso + Touchpoint applicato`,
  [TouchpointTypeEnum.HeatTransferableTNT]: $localize`Heat Transferable TNT`,
};
