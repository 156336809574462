import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';

import { Company } from '@labgo/commons';
import { PWACompanyService } from '../services/pwa-company.service';

@Injectable({
  providedIn: 'root',
})
export class PWACompanyResolver implements Resolve<Company> {
  constructor(
    private PWACompanyService: PWACompanyService,
    private router: Router
  ) {}

  resolve(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Company> {
    const companyName = routeSnapshot.params.companyName;
    this.PWACompanyService.fetchCompanyByName(companyName);

    return this.PWACompanyService.whenCurrentCompany();
  }
}
