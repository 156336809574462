import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, ReplaySubject } from 'rxjs';

import { LabgoEnvironment, LabgoLoggerService } from '@labgo/commons';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private userPosition$: ReplaySubject<GeolocationPosition> =
    new ReplaySubject<GeolocationPosition>(1);
  private qrId$: ReplaySubject<string> = new ReplaySubject<string>(1);

  geolocationData() {
    return forkJoin([
      this.userPosition$.asObservable().pipe(take(1)),
      this.qrId$.asObservable().pipe(take(1)),
    ]);
  }

  // get user geolocation with browser API
  getGeolocation() {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.userPosition$.next(pos);
      },
      (err: { code: number; message: string }) => {}
    );
  }

  constructor(
    @Inject('env') private env: LabgoEnvironment,
    private logger: LabgoLoggerService,
    private http: HttpClient
  ) {
    this.subscribeToScanData();
  }

  // when you have both qrID and user position, post data on backend
  subscribeToScanData() {
    this.geolocationData()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const geolocation = res[0];
          const id = res[1];

          const userPosition = {
            lat: geolocation.coords.latitude,
            lng: geolocation.coords.longitude,
          };

          // reset the subscription
          this.startSubscription();
          this.postGeolocation(id, userPosition);
        },
        error: (res: Error) => {
          console.error('Error while adding position\n', res.name, res.message);
        },
      });
  }

  // api call
  updateQrScanGeolocation(
    id: string,
    data: { lat: number; lng: number; browser?: string; file?: any }
  ) {
    return this.http.post<any>(
      `${this.env.server.url}/Qrcode/check/${id}/location`,
      data
    );
  }

  postGeolocation(id: string | null, data: { lat: number; lng: number }) {
    if (!id) return;
    this.updateQrScanGeolocation(id, data).subscribe({
      next: (res) => {
        this.logger.debug('qrService.updateQrScanGeolocation.NEXT', res);
      },
      error: (res: Error) => {
        console.error(
          'qrService.updateQrScanGeolocation.ERROR',
          res.name,
          res.message
        );
      },
    });
  }

  startSubscription() {
    this.userPosition$ = new ReplaySubject<GeolocationPosition>(1);
    this.qrId$ = new ReplaySubject<string>(1);

    this.subscribeToScanData();
  }

  nextQrId(id: string) {
    this.qrId$.next(id);
  }
}
