export enum CompanyAssociationStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Suspended = 'Suspended',
}

export const CompanyAssociationStatusLabel = new Map<string, string>([
  [CompanyAssociationStatus.Accepted, $localize`Licenza Attiva`],
  [CompanyAssociationStatus.Rejected, $localize`Licenza Rifiutata`],
  [CompanyAssociationStatus.Pending, $localize`Licenza in valutazione`],
  [CompanyAssociationStatus.Suspended, $localize`Licenza sospesa`],
]);
