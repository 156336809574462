<div class="back-btn-spacer"></div>

<div class="customer">
  <img class="customer-logo" [src]="company.customization?.logoUrl" />
</div>

<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
  <mat-icon>menu</mat-icon>
</button>

<mat-menu class="labgo-topbar-menu mat-elevation-z0" [hasBackdrop]="true" #menu>
  <!-- LOGIN DISABLED -->
  <ng-container *ngIf="false">
    <button mat-menu-item (click)="onPersonalArea()">
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19M13 5C13 7.20914 11.2091 9 9 9C6.79086 9 5 7.20914 5 5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5Z"
          stroke="var(--pwa-topbar-color)"
          fill="transparent"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>{{ "TOPBAR.ACCOUNT" | translate }}</span>
    </button>

    <button mat-menu-item (click)="onPersonalArea()">
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z"
          stroke="var(--pwa-topbar-color)"
          fill="transparent"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>{{ "TOPBAR.PERSONAL_AREA" | translate }}</span>
    </button>
  </ng-container>

  <button
    *ngFor="let item of menuItems"
    mat-menu-item
    (click)="onNavigate(item.routerLink)"
  >
    <mat-icon svgIcon="{{ item.icon }}"></mat-icon>
    {{ item.label | translate }}
  </button>

  <mat-chip-listbox
    [multiple]="false"
    [selectable]="true"
    aria-label="Language selection"
    class="labgo-chip-list language-selection"
  >
    <mat-chip-option
      class="labgo-chip"
      (click)="onSelectLanguage($event, 'it')"
      selectable="true"
      [selected]="selectedLanguage === 'it'"
    >
      <span class="sm">ITA</span>
    </mat-chip-option>
    <mat-chip-option
      class="labgo-chip"
      (click)="onSelectLanguage($event, 'en')"
      selectable="true"
      [selected]="selectedLanguage === 'en'"
    >
      <span class="sm">ENG</span>
    </mat-chip-option>
    <mat-chip-option
      class="labgo-chip"
      (click)="onSelectLanguage($event, 'es')"
      selectable="true"
      [selected]="selectedLanguage === 'es'"
    >
      <span class="sm">ES</span>
    </mat-chip-option>
    <mat-chip-option
      class="labgo-chip"
      (click)="onSelectLanguage($event, 'pt')"
      selectable="true"
      [selected]="selectedLanguage === 'pt'"
    >
      <span class="sm">PT</span>
    </mat-chip-option>
  </mat-chip-listbox>
</mat-menu>
