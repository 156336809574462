import { Route } from '@angular/router';

import { QRConfigGuard } from './core/guards/qr-config.guard';
import { QRLinkGuard } from './core/guards/qr-link.guard';
import { PWACompanyResolver } from './core/resolvers/pwa-company.resolver';
import { PWAUserResolver } from './core/resolvers/pwa-user.resolver';

import { QRRedirectGuard } from './core/guards/qr-redirect.guard';
import { PageContainerComponent } from './shared/components/page-container/page-container.component';

export const APP_ROUTES: Route[] = [
  { path: '', redirectTo: 'labgo/home', pathMatch: 'full' },
  {
    canActivate: [QRRedirectGuard],
    path: 'q',
    loadChildren: () => import('./qrscan/routes').then((m) => m.QRSCAN_ROUTES),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/routes').then((m) => m.HOME_ROUTES),
  },
  {
    path: ':companyName',
    resolve: { company: PWACompanyResolver },
    component: PageContainerComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () => import('./home/routes').then((m) => m.HOME_ROUTES),
      },
      {
        canActivate: [QRConfigGuard],
        path: 'qrscan',
        loadChildren: () =>
          import('./qrscan/routes').then((m) => m.QRSCAN_ROUTES),
      },
      {
        path: 'pages',
        canActivate: [QRLinkGuard],
        loadChildren: () =>
          import('./pages/routes').then((m) => m.PAGES_ROUTES),
      },
      {
        path: 'users',
        resolve: { user: PWAUserResolver },
        loadChildren: () => import('./users/routes').then((m) => m.USER_ROUTES),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'labgo/home',
  },
];
