import { CommonModule } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Company } from '@labgo/commons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'pwa-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  imports: [
    CommonModule,
    MatChipsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    TranslateModule,
  ],
})
export class TopbarComponent implements OnInit {
  translate = inject(TranslateService);

  company: Company;

  selectedLanguage = this.translate.getBrowserLang();

  menuItems = [
    {
      label: 'MENU.SCAN',
      icon: 'qr-code',
      routerLink: 'qrscan',
    },
    {
      label: 'MENU.PROFILE',
      icon: 'user-profile',
      routerLink: 'users/profile',
    },
    {
      label: 'MENU.ABOUT',
      icon: 'about',
      routerLink: 'pages/about',
    },
    {
      label: 'MENU.LEGAL',
      icon: 'legal',
      routerLink: 'pages/privacy',
    },
  ];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.company = this.activatedRoute.snapshot.data['company'];
  }

  ngOnInit(): void {}

  onSelectLanguage(evt: any, lang: string) {
    evt.stopPropagation();
    this.translate.use(lang);
  }

  onNavigate(target: string) {
    this.router.navigate(['/', this.company?.name, ...target.split('/')]);
  }

  onPersonalArea() {
    this.router.navigate(['/', this.company?.name, 'users', 'home']);
  }
}
