import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { Loader } from '@googlemaps/js-api-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService,
} from 'keycloak-angular';
import { APP_ROUTES } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { AuthGuard } from './app/core/guards/auth.guard';
import { initializeKeycloak } from './app/core/initializeKeycloak';
import { basicAuthInterceptor } from './app/core/interceptors/basic-auth.interceptor';
import { PwaService } from './app/core/services/pwa.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),

    provideRouter(
      APP_ROUTES,
      withPreloading(PreloadAllModules)
      // withDebugTracing()
    ),

    // Keycloak
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    KeycloakAngularModule,
    KeycloakService,
    KeycloakBearerInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
      deps: [KeycloakService],
    },
    AuthGuard,

    // HttpClient
    provideHttpClient(
      withInterceptors([basicAuthInterceptor]),
      withInterceptorsFromDi()
    ),

    PwaService,
    importProvidersFrom(
      MatSnackBarModule,
      MatDialogModule,
      MatDatepickerModule,
      MatNativeDateModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: false,
        registrationStrategy: 'registerWhenStable:30000',
      }),

      // Translation module ngx-translate
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      })
    ),

    // Environment DI availability
    {
      provide: 'env',
      useValue: environment,
    },

    // Google Maps loader
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyAm9i2g1F-CGOtMDIyTecouTZL24t8C-Fc',
        libraries: ['places'],
      }),
    },
  ],
});
