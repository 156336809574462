import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LabgoSpinner } from '../spinner/spinner.component';
import { LabgoSplashScreenBackground } from '../splash-screen-background/splash-screen-background';

@Component({
  standalone: true,
  imports: [CommonModule, LabgoSpinner, LabgoSplashScreenBackground],
  selector: 'splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
