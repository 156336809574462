import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import {
  CHECKID_TO_LINK_STORAGE_KEY,
  LabgoLoggerService,
  LocalStorageService,
  QRID_TO_LINK_STORAGE_KEY,
} from '@labgo/commons';

import { QRService } from '../../core/services/qr.service';
import { PWAAuthService } from '../services/pwa-auth.service';

@Injectable({
  providedIn: 'root',
})
export class QRLinkGuard implements CanActivate {
  constructor(
    private qrService: QRService,
    private pwaAuthService: PWAAuthService,
    private localStorageService: LocalStorageService,
    private logger: LabgoLoggerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await this.pwaAuthService.isAutenticated();

    if (isAuthenticated) {
      const q = this.localStorageService.get(QRID_TO_LINK_STORAGE_KEY);
      const checkId = this.localStorageService.get(CHECKID_TO_LINK_STORAGE_KEY);
      if (q && checkId) {
        this.qrService.linkQrCode(q, checkId).subscribe((__) => {
          this.logger.debug('QR Linked to User', q);
          this.logger.debug('CheckID sent', checkId);
          this.localStorageService.remove(QRID_TO_LINK_STORAGE_KEY);
          this.localStorageService.remove(CHECKID_TO_LINK_STORAGE_KEY);
        });
      }
    }

    return true;
  }
}
