import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'lgo-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class LabgoSpinner {
  // @Input() color: string = 'var(--labgo-topbar-color)';
  // @HostBinding('style.--lgo-spinner-color') @Input() color: string =
  //   'var(--labgo-topbar-color)';

  constructor() {}
}
