import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Company } from '../models/company.interface';

@Injectable({
  providedIn: 'root',
})
export class SkinService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setSkin(company: Company) {
    if (!company) return;
    // this.document.documentElement.style.setProperty(
    //   `--labgo-primary`,
    //   company.customization?.skin?.primary || '#000'
    // );
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute(
        'content',
        company.customization?.skin?.background || '#262626'
      );

    this.document.documentElement.style.setProperty(
      `--labgo-topbar-background`,
      company.customization?.skin?.background || '#fff'
    );
    this.document.documentElement.style.setProperty(
      `--labgo-topbar-color`,
      company.customization?.skin?.color || '#000'
    );

    this.document.documentElement.style.setProperty(
      `--labgo-custom-button-background`,
      company.customization?.skin?.background || '#fff'
    );
    this.document.documentElement.style.setProperty(
      `--labgo-custom-button-color`,
      company.customization?.skin?.color || '#000'
    );
    // set pwaAcolors variables
    this.document.documentElement.style.setProperty(
      `--pwa-topbar-background-color`,
      company.customization?.pwaColors?.topBarBackgroundColor ||
        company.customization?.skin?.background ||
        '#000'
    );
    this.document.documentElement.style.setProperty(
      `--pwa-topbar-color`,
      company.customization?.pwaColors?.topBarColor ||
        company.customization?.skin?.color ||
        '#fff'
    );
    this.document.documentElement.style.setProperty(
      `--pwa-primary-button-background-color`,
      company.customization?.pwaColors?.primaryButtonBackgroundColor ||
        company.customization?.skin?.background ||
        '#000'
    );
    this.document.documentElement.style.setProperty(
      `--pwa-primary-button-color`,
      company.customization?.pwaColors?.primaryButtonColor ||
        company.customization?.skin?.color ||
        '#fff'
    );
    this.document.documentElement.style.setProperty(
      `--pwa-secondary-button-background-color`,
      company.customization?.pwaColors?.secondaryButtonBackgroundColor ||
        company.customization?.skin?.color ||
        '#fff'
    );
    this.document.documentElement.style.setProperty(
      `--pwa-secondary-button-color`,
      company.customization?.pwaColors?.secondaryButtonColor ||
        company.customization?.skin?.background ||
        '#000'
    );
  }
}
