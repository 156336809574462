import { Role } from './role.enum';

export interface Permissions {
  scanDashboard: Permission;
  stores: Permission;
  products: Permission;
  events: Permission;
  requests: Permission;
  issues: Permission;
  users: Permission;
  engagementDashboard: Permission;
  advertisements: Permission;
  contests: Permission;
  geolocationDashboard: Permission;
  leads: Permission;
  scanInfo: Permission;
}

export enum PermissionKeys {
  scanDashboard = 'scanDashboard',
  stores = 'stores',
  products = 'products',
  events = 'events',
  requests = 'requests',
  issues = 'issues',
  users = 'users',
  engagementDashboard = 'engagementDashboard',
  advertisements = 'advertisements',
  contests = 'contests',
  geolocationDashboard = 'geolocationDashboard',
  leads = 'leads',
  scanInfo = 'scanInfo',
}

export const PERMISSION_GROUPS = {
  core: {
    label: $localize`Modulo Core`,
    permissions: {
      [PermissionKeys.events]: $localize`Eventi`,
      [PermissionKeys.issues]: $localize`Segnalazioni`,
      [PermissionKeys.products]: $localize`Prodotti`,
      [PermissionKeys.requests]: $localize`Richieste`,
      [PermissionKeys.users]: $localize`Utenti`,
    },
  },
  engagement: {
    label: $localize`Modulo Engagement`,
    permissions: {
      [PermissionKeys.advertisements]: $localize`Annunci`,
      [PermissionKeys.stores]: $localize`Negozi`,
      [PermissionKeys.contests]: $localize`Concorsi`,
      [PermissionKeys.engagementDashboard]: $localize`Dashboard Engagement`,
      [PermissionKeys.geolocationDashboard]: $localize`Dashboard Geolocalizzazione`,
      [PermissionKeys.leads]: $localize`Leads`,
      [PermissionKeys.scanDashboard]: $localize`Dashboard scansioni`,
      [PermissionKeys.scanInfo]: $localize`Scan Path`,
    },
  },
};

export interface Permission {
  create: boolean | undefined;
  read: boolean;
  update: boolean | undefined;
  delete: boolean | undefined;
}

export const PERMISSION_ROLES: Map<PermissionKeys, Role> = new Map<
  PermissionKeys,
  Role
>([
  [PermissionKeys.scanDashboard, Role.Admin],
  [PermissionKeys.stores, Role.Admin],
  [PermissionKeys.products, Role.Editor],
  [PermissionKeys.events, Role.Editor],
  [PermissionKeys.requests, Role.Editor],
  [PermissionKeys.issues, Role.Admin],
  [PermissionKeys.users, Role.Admin],
  [PermissionKeys.engagementDashboard, Role.Admin],
  [PermissionKeys.advertisements, Role.Admin],
  [PermissionKeys.contests, Role.Admin],
  [PermissionKeys.geolocationDashboard, Role.Admin],
  [PermissionKeys.leads, Role.Store],
  [PermissionKeys.scanInfo, Role.Admin],
]);
