import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { Company } from '@labgo/commons';
import { PWACompanyService } from '../../../core/services/pwa-company.service';
import { TopbarComponent } from '../topbar/topbar.component';

@Component({
  standalone: true,
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  imports: [CommonModule, RouterModule, TopbarComponent],
})
export class PageContainerComponent {
  company: Company;
  constructor(
    private PWACompanyService: PWACompanyService,
    private titleService: Title
  ) {
    this.company = this.PWACompanyService.currentCompany;
    this.titleService.setTitle(this.company.businessName + ' App');
  }
}
