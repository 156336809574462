import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  LabgoEnvironment,
  TermsAndConditionsCompany,
  User,
  UserResponse,
} from '@labgo/commons';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PWAUserService {
  currentUser$ = new ReplaySubject<User>(1);

  constructor(
    @Inject('env') private env: LabgoEnvironment,
    private http: HttpClient
  ) {}

  getMyUser(): Observable<User> {
    // return of((<unknown>me) as User);
    return this.http.get<User>(`${this.env.server.url}/User/me`).pipe(
      map((user) => {
        // user.additionalInfoCompleted = false;
        // user.mainInfoCompleted = false;
        // user.lastTermsAndConditionsAccepted = false;
        // console.log('USER', user);
        return user;
      }),
      tap((user) => this.currentUser$.next(user))
    );
  }

  whenCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  fetchMyUser(): Observable<User> {
    return this.http.get<User>(`${this.env.server.url}/User/me`).pipe(
      map((user) => {
        // user.additionalInfoCompleted = false;
        // user.mainInfoCompleted = false;
        // user.lastTermsAndConditionsAccepted = false;
        // console.log('USER', user);
        return user;
      }),
      tap((user) => this.currentUser$.next(user)),
      catchError((error) => {
        console.error('Failed to fetch user', error);
        return throwError(() => new Error('Failed to fetch user'));
      })
    );
  }

  deleteMyUser(): Observable<User> {
    return this.http.delete<User>(`${this.env.server.url}/User`);
  }

  updateUser(user: Partial<User>): Observable<UserResponse> {
    return this.http.put<UserResponse>(
      `${this.env.server.url}/User/${user.id}`,
      user
    );
  }

  completeProfile(params: {
    user: Partial<User>;
    instantWinId: string;
  }): Observable<UserResponse> {
    const httpParams = new HttpParams().append(
      'instantWinId',
      params.instantWinId
    );
    return this.http.post<UserResponse>(
      `${this.env.server.url}/User/instantWin`,
      params.user,
      { params: httpParams }
    );
  }

  acceptTermsAndConditions(
    data: Partial<TermsAndConditionsCompany>
  ): Observable<Partial<TermsAndConditionsCompany>> {
    return this.http.post<Partial<TermsAndConditionsCompany>>(
      `${this.env.server.url}/User/termsAndConditions`,
      data
    );
  }

  rejectTermsAndConditions(
    data: Partial<TermsAndConditionsCompany>
  ): Observable<Partial<TermsAndConditionsCompany>> {
    return this.http.delete<Partial<TermsAndConditionsCompany>>(
      `${this.env.server.url}/User/termsAndConditions/${data.id}`
    );
  }
}
