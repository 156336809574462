import { Injectable } from '@angular/core';

export const LANGUAGE_STORAGE_KEY = 'labgo-language';
export const COUNTRY_STORAGE_KEY = 'labgo-country';
export const COMPANY_NAME_STORAGE_KEY = 'labgo-company-name';
export const QRID_TO_LINK_STORAGE_KEY = 'labgo-qr-id';
export const CHECKID_TO_LINK_STORAGE_KEY = 'labgo-check-id';
export const LAST_TARGET_MARKETS_STORAGE_KEY = 'labgo-preferre-target-market';
export const LAST_SHIPPING_ADDRESS_STORAGE_KEY = 'labgo-last-shipping-address';
export const LAST_SHIPPING_NOTES_STORAGE_KEY = 'labgo-last-shipping-notes';

// SCAN PATH
export const SCAN_PATH__FILTERS = 'lgo-scan-path--filters';
export const GOOGLE_MAPS__FILTERS = 'lgo-google-maps--filters';
export const LEADS__FILTERS = 'lgo-leads--filters';

// PWA target url (for user completed guard)
export const TARGET_URL_STORAGE_KEY = 'labgo-target-url';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  localStorage: Storage;
  constructor() {
    this.localStorage = window.localStorage;
  }
  get(key: string): any {
    if (this.isLocalStorageSupported) {
      const value = this.localStorage.getItem(key);
      if (value) return JSON.parse(value);
    }
    return null;
  }
  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }
  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }
  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
